.kaushan-script-regular {
  font-family: 'Kaushan Script', cursive;
  font-weight: 400;
  font-size: 100px;
  font-style: normal;
  color: black;
}

.bannerimg {
  height: 55vh;
  width: 55vw;
  opacity: 1;
  cursor: pointer;
}

/* Extra-small */
@media screen and (max-width: 360px) {
  .bannerimg {
    height: 30vh;
    width: 100vw;
    opacity: 1;
    cursor: pointer;
  }
}

/* Small */
@media screen and (min-width: 361px) and (max-width: 480px) {
  .bannerimg {
    height: 30vh;
    width: 100vw;
    opacity: 1;
    cursor: pointer;
  }
}

/* Medium-only */
@media screen and (min-width: 481px) and (max-width: 960px) {
  .bannerimg {
    height: 30vh;
    width: 100vw;
    opacity: 1;
    cursor: pointer;
  }
}

@media screen and (min-width: 481px) and (max-width: 960px) {
  .bannerimg {
    height: 30vh;
    width: 100vw;
    opacity: 1;
    cursor: pointer;
  }
}
/* Large Screens */
/* @media screen and (min-width: 961px) and (max-width: 2000px) {
  img {
    height: 55vh;
    width: 55vw;
    opacity: 1;
    cursor: pointer;
  }
} */
